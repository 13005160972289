<template>
  <h2>會員管理</h2>

  <div class="my-4 text-end">
    <router-link :to="`/admin/member`">返回列表頁</router-link>
  </div>

  <form action="" @submit.prevent="updateMember()">
    <div class="card mb-5">
      <div class="card-header">
        <div class="h5 d-inline mb-0">修改會員</div>
        <!-- <button type="reset" class="btn btn-primary float-end">清除重填</button> -->
      </div>
      <div class="card-body">
        <!-- <pre>
          {{ data }}
        </pre> -->
        <div class="row" v-if="is_member_base_data_fetched">
          <!-- <div class="col-md-6">??</div>
              <div class="col-md-6">??</div> -->

          <div
            class="alert alert-danger"
            role="alert"
            v-if="data.status && data.status === 'delete'"
          >
            <i class="bi bi-exclamation-triangle"></i>
            該會員已經是停用的狀態
          </div>

          <!-- <div class="col-md-12">
            <div class="mb-4">
              <label class="form-label">經紀人身份</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked"
                  v-model="data.agent"
                />
                <label class="form-check-label" for="flexCheckChecked">
                  是經紀人
                </label>
              </div>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="mb-4">
              <label class="form-label">身份</label>
              <select
                class="form-select w-25"
                v-model="data.role"
                @change="changeRole()"
              >
                <option value="generally">一般會員</option>
                <option value="agent">經紀人</option>
                <option value="agentCompanyA">合作經紀公司 A</option>
                <option value="agentCompanyB">合作經紀公司 B</option>
              </select>
            </div>
          </div>

          <div class="col-md-12" v-if="data.photoURL">
            <div class="mb-3">
              <label class="form-label"> 會員頭像 </label>
              <div>
                <img :src="data.photoURL" class="img-thumbnail memberImg" />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">會員帳號</label>
              <div class="bg-light px-3 py-2 rounded" role="alert">
                {{ data.member_id }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"> 暱稱 </label>
              <input type="text" class="form-control" v-model="data.name" />
            </div>
          </div>
          <!--<div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"> 會員編號 (測試用, 先亂產生) </label>
              <div class="bg-light px-3 py-2 rounded" role="alert">
                {{ memberId }}
              </div>
            </div>
          </div>-->
          <div class="col-md-6" v-if="showParentMemberOption === true">
            <div class="mb-3">
              <label class="form-label"> 上線會員 </label>
              <div></div>
              <Multiselect
                v-model="data.parentMemberId"
                mode="single"
                :searchable="true"
                :options="parentMemberOption"
              />
              <router-link
                :to="`/admin/member/update/${data.parentMemberId}`"
                target="_blank"
                class="d-block mt-2"
                v-if="data.parentMemberId"
              >
                {{ parentMemberData.name }}
              </router-link>
            </div>
          </div>
          <div class="col-md-6" v-else>
            <div class="mb-3">
              <label class="form-label"> 上線會員 </label>
              <div class="bg-light px-3 py-2 rounded" role="alert">
                <router-link
                  :to="`/admin/member/update/${data.parentMemberId}`"
                  target="_blank"
                  v-if="data.parentMemberId"
                >
                  {{ parentMemberData.name }}
                </router-link>
                <div class="text-danger" v-else>無上線</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">生日</label>
              <div class="bg-light px-3 py-2 rounded" role="alert">
                <div v-if="data.birthday">
                  {{ data.birthday.toDate().toLocaleDateString() }}
                </div>
                <div class="text-danger" v-else>無填資料</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">
                <span class="text-danger">*</span>
                手機號碼 (格式請以 +8869xxxxxxxx)
              </label>
              <!-- <div v-if="data.mobile">{{ data.mobile }}</div>
              <div class="text-danger" v-else>無填資料</div> -->
              <input type="text" class="form-control" v-model="data.mobile" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">電子信箱</label>
              <!-- <div v-if="data.email">{{ data.email }}</div>
              <div class="text-danger" v-else>無填資料</div> -->
              <input type="text" class="form-control" v-model="data.email" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">服務店家</label>

              <select class="form-select" v-model="data.store">
                <option value="">--無--</option>
                <option v-for="(store, idx) in storeOptions" :key="idx">
                  {{ store }}
                </option>
              </select>

              <!-- {{ data.store }}
              <br>
              {{ storeOptions }} -->
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">編號藝名</label>
              <input
                type="text"
                class="form-control"
                v-model="data.numberStageName"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">App登入用密碼</label>
              <!-- <input
                type="password"
                class="form-control"
                v-model="authPassword"
              /> -->
              <div class="input-group mb-1">
                <input
                  type="password"
                  class="form-control"
                  v-model="authPassword"
                />
                <button
                  class="btn btn-warning"
                  type="button"
                  id="button-addon2"
                  @click="updateAppPassword()"
                >
                  <span v-if="authPasswordLoading === false">確認修改</span>
                  <span v-else>修改中...</span>
                </button>
              </div>
              <small class="text-secondary"
                >如果有需要修改密碼請填寫後點擊右方確認修改，密碼至少7碼</small
              >
            </div>
          </div>
        </div>

        <!-- <div class="text-center mt-5">
          <button type="submit" class="btn btn-success btn-lg">儲存</button>
        </div> -->
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-md-5">
        <div class="card">
          <div class="card-header">
            <div class="h5 d-inline mb-0">本月收入</div>
            <div class="float-end">
              <span class="me-4">
                歷史累積分潤：
                <span v-if="data.totalBonus">{{ data.totalBonus }}</span>
                <span v-else>0</span>
              </span>
              <span>
                G幣餘額：
                <span v-if="data.balance">{{ data.balance }}</span>
                <span v-else>0</span>
              </span>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item mb-4">
                個人經紀費：
                <span v-if="bonusDetail.brokerageFee">{{
                  bonusDetail.brokerageFee
                }}</span>
                <span v-else>0</span>
              </li>
              <!-- <li class="list-group-item">
                個人分潤({{ bonusDetail.selfBonusPercentage }}%)：{{
                  bonusDetail.selfBonusDetail
                }}
              </li> -->
              <li class="list-group-item">
                第一代下線總分潤(<span v-if="bonusDetail.Level1bonuspercentage"
                  >{{ bonusDetail.Level1bonuspercentage }} %</span
                ><span v-else>-</span>)：
                <span v-if="bonusDetail.L1BonusDetailTotal">{{
                  bonusDetail.L1BonusDetailTotal
                }}</span>
                <span v-else>0</span>
              </li>
              <li class="list-group-item">
                第二代下線總分潤(<span v-if="bonusDetail.Level2bonuspercentage"
                  >{{ bonusDetail.Level2bonuspercentage }} %</span
                ><span v-else>-</span>)：
                <span v-if="bonusDetail.L2BonusDetailTotal">{{
                  bonusDetail.L2BonusDetailTotal
                }}</span>
                <span v-else>0</span>
              </li>
              <li class="list-group-item">
                第三代下線總分潤(<span v-if="bonusDetail.Level3bonuspercentage"
                  >{{ bonusDetail.Level3bonuspercentage }} %</span
                ><span v-else>-</span>)：
                <span v-if="bonusDetail.L3BonusDetailTotal">{{
                  bonusDetail.L3BonusDetailTotal
                }}</span>
                <span v-else>0</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="card">
          <div class="card-header h5">
            <div class="h5 d-inline mb-0">G幣明細紀錄</div>

            <button
              class="btn btn-info float-end"
              type="button"
              @click="showModal()"
            >
              人工存提G幣
            </button>

            <!-- <button type="button" class="btn float-end" @click="getTransactionDatas()" v-if="transactionDatas.length>0">
              <i class="bi bi-arrow-clockwise"></i> 重新抓取
            </button> -->
          </div>
          <div class="card-body recordBody px-0 py-0">
            <table class="table table-hover">
              <thead class="sticky-top bg-light">
                <tr>
                  <th>序號</th>
                  <th>G幣金額</th>
                  <th>交易類型</th>
                  <th>交易時間</th>
                  <th>備註</th>
                  <th>作業人員</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactionDatas"
                  :key="index"
                >
                  <td width="50">{{ index + 1 }}</td>
                  <td width="150" :class="transaction.data.doType">
                    <span v-if="transaction.data.doType === 'out'">-</span
                    >{{ transaction.data.bonus }}
                  </td>
                  <td width="150" :class="transaction.data.doType">
                    {{ transaction.data.transactionType }}
                  </td>
                  <td width="100">
                    {{ transaction.data.entryTime.toDate().toLocaleString() }}
                  </td>
                  <td class="text_break_line">{{ transaction.data.remark }}</td>
                  <td width="150">{{ transaction.data.creator }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">人工存提G幣</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-2"><b>會員姓名：</b>{{ data.name }}</div>
            <div class="mb-2"><b>服務店家：</b>{{ data.store }}</div>
            <div class="mb-2"><b>電話：</b>{{ data.mobile }}</div>
            <div class="mb-2"><b>目前G幣金額：</b>{{ data.balance }}</div>

            <hr />

            <form action="" @submit.prevent="transactionSubmit()">
              <div class="mb-3 mt-4 row">
                <label class="col-sm-2 col-form-label"></label>
                <div class="col-sm-10">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="inlineRadio1"
                      value="in"
                      v-model="transaction.transactionDoType"
                    />
                    <label class="form-check-label" for="inlineRadio1"
                      >存入</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="inlineRadio2"
                      value="out"
                      v-model="transaction.transactionDoType"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >提出</label
                    >
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label"
                  ><span class="text-danger">*</span> G幣金額</label
                >
                <div class="col-sm-10">
                  <input
                    type="number"
                    class="form-control"
                    min="0"
                    required
                    v-model="transaction.transactionBonus"
                    :placeholder="
                      transaction.transactionBonusPlaceholder[
                        transaction.transactionDoType
                      ]
                    "
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label"
                  ><span class="text-danger">*</span> 類型</label
                >
                <div class="col-sm-10">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    required
                    v-model="transaction.transactionTypeSelected"
                  >
                    <option
                      v-for="(item, index) in transaction
                        .transactionTypeOptions[transaction.transactionDoType]"
                      :key="index"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label">&nbsp;&nbsp;備註</label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="transaction.transactionRemark"
                  ></textarea>
                </div>
              </div>
              <div class="text-center mt-3">
                <button
                  type="button"
                  class="btn btn-secondary btn-lg"
                  @click="hideModal()"
                >
                  取消
                </button>
                &nbsp;&nbsp;
                <button type="submit" class="btn btn-primary btn-lg">
                  送出
                </button>
                <!-- @click="transactionSubmit()" -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="h5 d-inline mb-0">團隊總覽</div>
          </div>
          <div class="card-body" id="orgCardBody">
            <OrgTree :tree="memberTree"></OrgTree>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="h5 d-inline mb-0">本月紀錄</div>
          </div>
          <div class="card-body recordBody">
            <div class="row">
              <div class="col">
                <div class="h6">打卡記錄</div>
                <div v-if="checkIn.length > 0">
                  <span class="badge bg-danger mb-2"
                    >{{ checkIn.length }}次</span
                  >
                  <ul>
                    <li v-for="(c, cindex) in checkIn" :key="cindex">
                      {{ c.toDate().toLocaleDateString() }}
                    </li>
                  </ul>
                </div>
                <div v-else-if="checkIn === false">
                  <p class="fst-italic">無打卡記錄</p>
                </div>
              </div>
              <!-- <div class="col">
                <div class="h6">求救記錄</div>
                <div>xxx</div>
                <div>xxx</div>
                <div>xxx</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="h5 d-inline mb-0">指定分潤比例</div>
            <div class="float-end">
              <i class="bi bi-exclamation-square-fill"></i>
              已結算分潤將不受影響
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label class="form-label">第一代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level1bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第二代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level2bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">第三代 下限分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.Level3bonuspercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
            <div class="mb">
              <label class="form-label">自身分潤比例</label>
              <div class="input-group">
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model.number="data.selfBonusPercentage"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div
      class="text-center mt-5"
      v-if="!(data.status && data.status === 'delete')"
    >
      <button type="submit" class="btn btn-success btn-lg">儲存</button>
    </div>
  </form>

  <div class="my-5 text-center">
    <router-link :to="`/admin/member`">返回列表頁</router-link>
  </div>
</template>

<script>
import { settingService } from "../../../services/settingService.js";
import { memberService } from "../../../services/memberService.js";
import { variableService } from "../../../services/variableService.js";
import Multiselect from "@vueform/multiselect";
import OrgTree from "@/components/OrgTree.vue";
import { Modal } from "bootstrap";
import axios from "axios";
export default {
  components: {
    Multiselect,
    OrgTree,
  },
  data() {
    return {
      parentMemberOption: [],
      memberId: "",
      data: {},
      myModal: null,
      authPassword: "",
      authPasswordLoading: false,
      parentMemberData: {},
      transaction: {
        transactionDoType: "in", // 手工G幣存提 in , out
        transactionBonus: "",
        transactionBonusPlaceholder: {
          in: "請輸入欲存入的金額",
          out: "請輸入欲提出的金額",
        },
        transactionTypeOptions: {
          in: ["車資補貼", "生日禮金", "活動獎金", "其他存入"],
          out: ["G幣兌現", "修正扣回", "其他提取"],
        },
        transactionTypeSelected: "",
        transactionRemark: "",
      },

      transactionDatas: [],

      showParentMemberOption: true,
      is_member_base_data_fetched: false,
      memberTree: [],
      bonusDetail: {},
      checkIn: [],
      storeOptions: [],
    };
  },
  methods: {
    changeRole() {
      if (this.data.role === "agent") {
        this.data.agent = true;
      } else {
        this.data.agent = false;
      }
    },
    async updateMember() {
      // 判斷服務店家是否存在
      if (
        (await settingService.checkInarrayStores(this.data.store)) === false
      ) {
        alert("服務店家不存在");
        return false;
      }

      const result = await memberService.updateMember(this.memberId, this.data);

      await variableService.init_allMember();

      alert(result.msg);

      if (result.res) {
        this.$router.push({ name: "member" });
      }
    },
    async updateAppPassword() {
      try {
        this.authPasswordLoading = true;
        await memberService.updateMemberAuthPassword(
          this.data.mobile,
          this.authPassword
        );
        alert("修改密碼完畢");
      } catch (error) {
        alert(error);
      } finally {
        this.authPasswordLoading = false;
        this.authPassword = "";
      }
    },
    showModal() {
      this.myModal = new Modal(document.getElementById("exampleModal"), {});
      this.myModal.show();
    },
    hideModal() {
      this.myModal.hide();
    },
    async transactionSubmit() {
      if (confirm("是否確定資訊都正確無誤？")) {
        const res = await memberService.transaction(
          this.transaction,
          this.memberId
        );
        // console.log(res);
        alert(res.msg);

        // 轉帳結果為成功
        if (res.result === 1) {
          // 推播訊息
          this.pushNotification(
            this.memberId,
            this.transaction.transactionTypeSelected,
            this.transaction.transactionBonus,
            this.transaction.transactionDoType
          );
          this.myModal.hide();
          this.transaction.transactionDoType = "in";
          this.transaction.transactionBonus = "";
          this.transaction.transactionTypeSelected = "";
          this.transaction.transactionRemark = "";

          this.data.balance = res.balance;
          this.data.totalBonus = res.totalBonus;

          // 手動存提G幣之後，直接把新增結果放到transactionDatas陣列的最前面顯示
          this.transactionDatas.unshift({ data: res.trans });

          // console.log(this.transactionDatas);
        }
      }
    },
    async getTransactionDatas() {
      this.transactionDatas = await memberService.getMemberTransaction(
        this.memberId
      );
      // console.log(this.transactionDatas);
    },
    pushNotification(memberId, content, amount, type) {
      axios
        .post(
          "https://us-central1-gx-economic-adviser.cloudfunctions.net/feeSplitting",
          {
            memberId: memberId,
            content: content,
            amount: amount,
            type: type,
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async created() {
    const member = await memberService.getOneMember(
      this.$route.params.memberId
    );

    if (!member) {
      alert("查無此會員");
      this.$router.push({ name: "member" });
    } else {
      this.data = member.data;
      this.memberId = member.id;

      const hasChild = await memberService.hasChild(this.memberId);
      if (hasChild === true) {
        this.showParentMemberOption = false;
      } else {
        this.showParentMemberOption = true;
        // 取得會員名單
        this.parentMemberOption =
          await memberService.getMembersSelectOptions_withOutOne(this.memberId);
      }

      if (this.data["parentMemberId"]) {
        const parentMemberData = await memberService.getOneMember(
          this.data["parentMemberId"]
        );
        this.parentMemberData = parentMemberData.data;
      }

      this.is_member_base_data_fetched = true;

      // 取得團隊總覽
      this.memberTree = await memberService.getTreeMember(this.memberId);

      const now = new Date();

      // 取得本月分潤
      this.bonusDetail = await memberService.getMemberBonusDetail(
        this.memberId,
        now.getFullYear(),
        now.getMonth() + 1
      );

      // 取得本月打卡記錄
      this.checkIn = await memberService.getMemberCheckIn(
        this.memberId,
        now.getFullYear(),
        now.getMonth() + 1
      );

      // 服務店家清單
      this.getTransactionDatas();

      // 服務店家清單
      this.storeOptions = await settingService.getStores();

      // this.showModal(); //test
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

td.in {
  color: #198754;
}

td.out {
  color: #dc3545;
}

#orgCardBody {
  overflow-x: auto;
}

.memberImg {
  max-height: 250px;
}

.recordBody {
  max-height: 245px;
  overflow-x: auto;
}

.text_break_line {
  white-space: pre-wrap;
}
</style>
