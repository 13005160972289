import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

const db = getFirestore();

export class settingService {
  static async getStores() {
    let storeOptions = [];

    await getDoc(doc(db, "setting", "gx_stores")).then((doc) => {
      if (doc.data()) {
        storeOptions = doc.data()["stores"];
      }
    });

    return storeOptions;
  }

  // 判斷該店家是否在目前的清單內
  static async checkInarrayStores(store) {
    const storeOptions = await this.getStores();

    // console.log(storeOptions)
    // console.log(storeOptions.includes(store))

    if (store && !storeOptions.includes(store)) {
      return false;
    }

    return true;
  }
}
