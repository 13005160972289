import { getFirestore } from "firebase/firestore";
import {
  query,
  getDocs,
  getDoc,
  collection,
  setDoc,
  doc,
  orderBy,
} from "firebase/firestore";

const db = getFirestore();

export class variableService {
  static async init_allMember() {
    // 抓取所有的會員資料
    const members = {};

    const membersRef = collection(db, "members");
    const q = query(membersRef, orderBy("registerTime", "desc"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      if (data.name == undefined) {
        data.name = null;
      }

      if (data.mobile == undefined) {
        data.mobile = null;
      }

      if (data.member_id == undefined) {
        data.member_id = null;
      }

      if (data.status == undefined) {
        data.status = "normal";
      }

      if (data.store == undefined) {
        data.store = null;
      }

      if (data.numberStageName == undefined) {
        data.numberStageName = null;
      }

      members[doc.id] = {
        name: data.name,
        mobile: data.mobile,
        member_id: data.member_id,
        status: data.status,
        store: data.store,
        numberStageName: data.numberStageName,
      };
    });

    // 寫入共用參數的allMembers資料內
    const variableRef = doc(db, "variable", "allMembers");
    setDoc(variableRef, { data: members });
  }

  // 取得所有會員的下拉清單
  static async get_allMember_selectOption(
    onlyNormal = false,
    shows = ["name", "mobile", "member_id", "store", "numberStageName"]
  ) {
    const docRef = doc(db, "variable", "allMembers");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const doc = docSnap.data();
      const members = [];

      for (let key in doc.data) {
        const data = doc.data[key];
        let label = "";

        if (data.name && shows.includes("name")) {
          label += data.name;
        }

        if (data.mobile && shows.includes("mobile")) {
          label += " ， " + data.mobile;
        }

        if (data.member_id && shows.includes("member_id")) {
          label += " ， " + data.member_id;
        }

        if (data.store && shows.includes("store")) {
          label += " ， " + data.store;
        }

        if (data.numberStageName && shows.includes("numberStageName")) {
          label += " ， " + data.numberStageName;
        }

        // 如果有設定只抓「啟用」的會用，但該會員不是啟用的，那就不顯示
        if (onlyNormal == true && data.status != "normal") {
          continue;
        } else {
          members.push({
            value: key,
            label: label,
            status: data.status,
          });
        }
      }

      return members;
    } else {
      // doc.data() will be undefined in this case
      return false;
    }
  }
}
