<template>
  <div class="tree">
    <ul>
      <li v-for="(item, index) in tree" :key="index">
        <!-- <a href="`/admin/member/update/${item.id}`" target="_blank">{{ item.name }}</a> -->

        <router-link
          :to="`/admin/member/${linkType}/${item.id}`"
          target="_blank"
        >
          <div>{{ item.name }}</div>
          <span class="badge bg-secondary" v-if="item.status == 'delete'"
            >已停用</span
          >
        </router-link>

        <template v-if="item.children && item.children.length > 0">
          <OrgTree :tree="item.children"></OrgTree>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OrgTree",
  data() {
    return {
      linkType: null,
    };
  },
  props: {
    tree: Object,
  },
  created() {
    if (
      this.$store.state.adminUserRole === "sys" ||
      this.$store.state.adminUserRole === "admin"
    ) {
      this.linkType = "update";
    } else {
      this.linkType = "update"; // view
    }
    // console.log(this.linkType)
  },
};
</script>

<style scoped>
/*Now the CSS*/
.tree {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.tree ul {
  display: flex;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.tree li {
  /* float: left; */
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
/*We will use ::before and ::after to draw the connectors*/
.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.tree li:after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}
/*We need to remove left-right connectors from elements without any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}
/*Remove left connector from first child and right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
  margin-left: -1px;
}
.tree li a {
  border: 1px solid #ccc;
  padding: 10px 26px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + .tree ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover + .tree ul li::after,
.tree li a:hover + .tree ul li::before,
.tree li a:hover + .tree ul::before,
.tree li a:hover + .tree ul ul::before {
  border-color: #94a0b4;
}
li a.just-line {
  display: none;
}
a.just-line + ul {
  padding-top: 74px;
}
a.just-line + ul:before {
  height: 74px;
}
</style>
